export const optionsList = () => [
  { label: i18n.get('查看'), value: 'freeflow.view', disabled: true },
  { label: i18n.get('回退'), value: 'freeflow.back' },
  { label: i18n.get('转交'), value: 'freeflow.addnode' },
  { label: i18n.get('导出'), value: 'freeflow.export' },
  { label: i18n.get('评论'), value: 'freeflow.comment' },
  { label: i18n.get('打印'), value: 'freeflow.print' },
];

const actions = () => {
  const result = {};
  optionsList().forEach((item) => {
    result[item.value] = item.label;
  });
  return result;
};

export const formAuthorizations = [
  {
    label: i18n.get('单据基础管理权限 '),
    value: 'custom',
    explain: i18n.get(
      '管理员可对其分发范围内的所有单据进行「查看」、「评论」、「打印」的操作 （可单选或多选）',
    ),
  },
  {
    label: i18n.get('报销单管理权限 '),
    value: 'expense',
    explain: i18n.get('管理员根据系统下发的权限进行指定范围内的报销单管理'),
    isShowInMenu: true,
    showMenuDesc: i18n.get('单据管理菜单栏中不展示该权限'),
  },
  {
    label: i18n.get('借款管理权限 '),
    value: 'loan',
    note: i18n.get(
      ' 注：设定「还款管理权限」范围后，借款包相关的 「还款确认通知」将推送至对应的分发管理员。',
    ),
    explain: i18n.get('管理员根据系统下发的权限进行指定范围内的借款包管理'),
    isShowInMenu: true,
    showMenuDesc: i18n.get('单据管理菜单栏中不展示该权限'),
    menuTip: i18n.get('勾选后，单据管理菜单栏不展示该权限，且对应的管理员不会收到还款通知'),
  },
  {
    label: i18n.get('申请管理权限 '),
    value: 'requisition',
    explain: i18n.get('管理员根据系统下发的权限进行指定范围内的申请事项管理'),
  },
  {
    label: i18n.get('收款单管理权限'),
    value: 'receipt',
    explain: i18n.get('管理员根据系统下发的权限进行指定范围内的收款单管理'),
    isShow: (permission) => permission.RECEIPT_DOCUMENT,
  },
];
export const operatorValue = {
  '>=': i18n.get('≥'),
  '<=': i18n.get('≤'),
  '>': '>',
  '<': '<',
  '=': i18n.get('等于'),
  in: i18n.get('是'),
  'not in': i18n.get('不是'),
  true: i18n.get('是'),
  false: i18n.get('否'),
};

export function getActions(data, record) {
  /** =======>>>>>鱼刺 待接口调通  修改内容*/
  let authorization = record && record.formAuthorization;
  let text = formAuthorizations.find((v) => v.value === authorization) || {};
  if (authorization === 'custom')
    return text.label + '(' + data.map((item) => actions()[item]).join(i18n.get('、')) + ')';
  return text.label;
}

export function getMenuActions(data) {
  const actions = [];
  data.forEach((i) => {
    i.children.forEach((j) => {
      j.select && actions.push(j);
    });
  });
  return {
    names: actions.map((i) => i.name).join(', '),
    ids: actions.map((i) => i.id),
  };
}

export function getStaffs(data, roleList) {
  if (data.type === 'ROLE') {
    let role = getStaffSelection(data, roleList);
    if (role) {
      let roles = role.roles.filter((o) => o.roleDefId === data.roleDefId);
      return role.name + (roles.length > 0 ? roles[0].name : '');
    } else {
      return '';
    }
  } else {
    return getIdList(data);
  }
}

function getIdList(data) {
  let result = [];
  if (Array.isArray(data.staffIds)) {
    result = result.concat(data.staffIds.map((item) => item && item.name));
  }
  if (Array.isArray(data.departments)) {
    result = result.concat(data.departments.map((item) => item && item.name));
  }
  if (Array.isArray(data.roles)) {
    result = result.concat(data.roles.map((item) => item && item.name));
  }
  return result.map((o) => o).join(i18n.get('、'));
}

export function getStaffSelection(data, roleList) {
  if (roleList && roleList.length > 0) {
    for (let item of roleList) {
      if (data.fieldName) {
        if (item.roles[0].fieldName === data.fieldName) return item;
      } else {
        if (item.source === data.source) return item;
      }
    }
  }
  return '';
}

export function validateStaff(staffSelection) {
  if (!staffSelection) return false;
  let flag = true;
  if (staffSelection.type === 'ROLE') {
    if (!staffSelection.source || !staffSelection.roleDefId) {
      flag = false;
    }
  } else {
    if (
      staffSelection.staffIds.length == 0 &&
      staffSelection.departments.length == 0 &&
      staffSelection.roles.length == 0
    ) {
      flag = false;
    }
  }
  return flag;
}

export function validateFilter(cData) {
  let flag = true;

  if (!cData) {
    return false;
  }

  if (cData.length === 0) {
    return true;
  }

  outer: for (let i = 0; i < cData.length; i++) {
    let line = cData[i];
    for (let j = 0; j < line.length; j++) {
      let item = line[j];
      flag = item.key && item.operator && item.value && item.value.length;
      if (!flag) {
        break outer;
      }
      delete item.stafftype;
    }
  }

  return flag;
}

export function getCopyData(data) {
  return {
    name: data.name + `(${i18n.get('副本')})`,
    filter: data.filter,
    staffSelection: getCopyStaffs(data.staffSelection),
    actions: data.actions,
  };
}
export function formatData(data) {
  return {
    name: data.name,
    filter: data.filter,
    staffSelection: getCopyStaffs(data.staffSelection),
    actions: data.actions,
    formAuthorization: data.formAuthorization,
    notDisplay: data.notDisplay,
  };
}
function getCopyStaffs(data) {
  if (data.type === 'ROLE') {
    delete data.isSkipped;
    return data;
  } else {
    return {
      type: data.type,
      departments: data.departments.map((o) => o.id),
      staffIds: data.staffIds.map((o) => o.id),
      roles: data.roles.map((o) => o.id),
      departmentsIncludeChildren: data.departmentsIncludeChildren,
    };
  }
}
