/**
 *  Created by daiwenjuan on 2018/1/24 下午4:50.
 */
// @i18n-ignore-all
import { app as api} from '@ekuaibao/whispered'
import moment from 'moment'
let conditonalInclude = ['organization.Department', i18n.get('basedata.Dimension.项目')] //PRO-17551 项目也显示包含子项目
export function isInclude(datatype, staffType) {
  let { entity, type = '' } = datatype || {}
  return (
    type === 'ref' &&
    ((entity === 'organization.Staff' && staffType === 'departments') || conditonalInclude.indexOf(entity) >= 0)
  )
}

export function isCleanPredicate(type, value) {
  if (!value) return
  let map = {}
  map['number'] = [i18n.get('≥'), i18n.get('≤'), '>', '<']
  map['money'] = map['number']
  map['boolean'] = ['=']
  map['ref'] = ['in', 'not in']
  map['list'] = ['in', 'not in']
  map['date'] = ['<', '>=', '>', '<=', 'in', 'not in']
  let val = map[type] || []
  return val && val.indexOf(value) < 0
}

export function describeArr() {
  return [
    { label: i18n.get('是'), value: 'true' },
    { label: i18n.get('否'), value: 'false' }
  ]
}
//KA-PRD-202109  变更申请 alterFlag 、分摊明细apportions、审批人validApprover
export function describeArrForSpecialField(key) {
  switch(key){
    case 'alterFlag':
      return [
        { label: i18n.get('是'), value: '1' },
        { label: i18n.get('否'), value: '0' }
      ]
    case 'apportions':
      return [
        { label: i18n.get('有'), value: 'true' },
        { label: i18n.get('无'), value: 'false' }
      ]
    case 'validApprover':
      return [
        { label: i18n.get('前序有效节点审批人'), value: 'BEFORE_APPROVER' },
        { label: i18n.get('后序有效节点审批人'), value: 'AFTER_APPROVER' },
        { label: i18n.get('其他有效节点审批人'), value: 'OTHER_APPROVER' }
      ]
  }
}

let moneyFields = [
  { label: i18n.get('≥'), value: '>=' },
  { label: i18n.get('≤'), value: '<=' },
  { label: '>', value: '>' },
  { label: '<', value: '<' }
]
let numberFields = moneyFields
let booleanFields = [
  {
    label: i18n.get('等于'),
    value: '='
  }
]
let equalField = [
  {
    label: i18n.get('不等于'),
    value: '!='
  }
]
let refFields = [
  { label: i18n.get('是'), value: 'in' },
  { label: i18n.get('不是'), value: 'not in' }
]

let listFields = [
  { label: i18n.get('包含'), value: 'in' },
  { label: i18n.get('不包含'), value: 'not in' }
]
const cityCondition = [
  { label: i18n.get('属于'), value: 'belong' },
  { label: i18n.get('不属于'), value: 'not belong' }
]

let stateFields = [
  { label: i18n.get('等于'), value: '=' },
]

export const multipleStaffsCondition = [
  { label: i18n.get('包含'), value: 'in' },
  { label: i18n.get('不包含'), value: 'not in' },
  { label: i18n.get('相同'), value: 'same' },
  { label: i18n.get('不相同'), value: 'not same' },
  { label: i18n.get('包含(字段)'), value: 'in flow' },
  { label: i18n.get('不包含(字段)'), value: 'not in flow' }
]
export function predicateFields(type, entity, from = '', key = null) {
  const equalFields = ['validApprover']
  if (entity === 'basedata.city') {
    return cityCondition
  }
  if (type === 'text' && from === 'permission') {
    return stateFields;
  }
  return {
    money: moneyFields,
    number: numberFields,
    date: dateFields(),
    boolean: key && equalFields.includes(key) ? equalField : booleanFields,
    ref: refFields,
    list: listFields
  }[type]
}

let staffsChildren = [
  { label: i18n.get('的部门'), value: 'departments' },
  { label: i18n.get('的角色'), value: 'roleId' },
  { label: i18n.get('本人'), value: '' }
]
let staffsChildrenFlow = [
  { label: i18n.get('的部门'), value: 'departments' },
  { label: i18n.get('的角色'), value: 'roleId' },
  { label: i18n.get('的职级'), value: 'E_system_rank' },
  { label: i18n.get('本人'), value: '' }
]
let staffsChildrenByLoan = [
  { label: i18n.get('的部门'), value: 'departments' },
  { label: i18n.get('的角色'), value: 'roleId' },
  { label: i18n.get('本人'), value: '' }
]
let multipleStaffsChildren = [
  { label: i18n.get('的部门'), value: 'departments' },
  { label: i18n.get('的角色'), value: 'roleId' },
  { label: i18n.get('的职级'), value: 'E_system_rank' },
  { label: i18n.get('员工'), value: '' }
]
export function formatGlobalFields(fields, currencyPower, from) {
  return fields
    .filter(item => isAvailableFields(item, from))
    .map(line => {
      return formatField(line, currencyPower, from)
    })
}

function formatField(line, currencyPower, from) {
  let { dataType } = line
  let result = {
    ...line,
    value: line.name,
    label: line.label
  }
  if (dataType.type === 'money') {
    result.value = result.value + '.standard'
    result.label = currencyPower ? result.label + i18n.get('（本位币）') : result.label
  }
  if (line.name === 'tripFromCity' && line.ability === 'trip') {
    result.label = `${line.label}(行程)`
  }
  if (line.name === 'tripToCity' && line.ability === 'trip') {
    result.label = `${line.label}(行程)`
  }
  if (dataType.type === 'ref' && dataType.entity === 'organization.Staff') {
    if (from === 'loanWriteOff') {
      result.children = staffsChildrenByLoan
      return result
    }
    result.children = from === 'conditionalFlow'? staffsChildrenFlow : staffsChildren
  }
  if(dataType.type === 'list' && dataType.elemType?.entity === 'organization.Staff'){
    result.children = multipleStaffsChildren
  }
  return result
}

let refWriteTypes = [
  'organization.Department',
  'basedata.Dimension',
  'flow.FeeType',
  'form.Specification.Versioned',
  'organization.Staff',
  'basedata.city'
]

let refWriteLoanTypes = [
  'organization.Department',
  'basedata.Dimension',
  'flow.FeeType',
  'form.Specification.Versioned',
  'organization.Staff',
  'basedata.city',
  'datalink.DataLinkEntity'
]
const blackTypes = [
  'payerId',
  'multiplePayeesMode',
  'lastPrintTime',
  'lastPrinter',
  'printState',
  'printCount',
  'payPlanMode',
  'profitAndLossSummary',
  'expenseMoneySumByCurrency',
  'writtenOffSumByCurrency',
  'companyRealPaySumByCurrency',
  'payMoneySumByCurrency',
  'writtenOffByDetails',
  'payMoneyByDetails',
  'settlementMoney',
  'reconciliationMoney',
  'forbidOwnerPrint'
]
const blackAbility = ['apportion', 'trip', 'feeDetail'] //过滤表体字段
const whiteList = ['submitDate', 'expenseDate']
const loanBlackType = [
  'payerId',
  'multiplePayeesMode',
  'lastPrintTime',
  'lastPrinter',
  'printState',
  'printCount',
  'payPlanMode',
  'timeToEnterPendingPayment',
  'forbidOwnerPrint',
  'settlementMoney',
  'reconciliationMoney',
  'reviewStatus',
  'invoiceBagCode',
  'linkDetailEntityId',
  'voucherNo',
  'voucherStatus',
  'voucherCreateTime',
  'dataLinkId',
  'tripId',
  'tripDate',
  'tripCity',
  'tripFromCity',
  'tripToCity',
  'state',
  'writtenOffMoney',
  'expenseDate',
  'feeTypeId',
  'amount',
  'apportionPercent',
  'apportionMoney',
  'detailId',
  'apportionId',
  'loanBalance',
  'expenseMoney',
  'companyRealPay',
  'specificationId',
  'profitAndLossSummary',
  'expenseMoneySumByCurrency',
  'writtenOffSumByCurrency',
  'companyRealPaySumByCurrency',
  'payMoneySumByCurrency',
  'writtenOffByDetails',
  'payMoneyByDetails',
]
function isAvailableFields(line = {}, from) {
  let { dataType, name, ability } = line
  let { entity, type } = dataType || {}
  let writeTypes = ['number', 'money', 'boolean', 'ref']
  let blackList = blackTypes
  let types = refWriteTypes
  if (from === 'loanWriteOff') {
    types = refWriteLoanTypes
    writeTypes = ['number', 'money', 'boolean', 'ref', 'date', 'text']
    // PRP-25467 过滤全局字段 进入待支付时间、制单人不可打印、结算金额（本位币）、对账金额（本位币）、负荷状态、发票袋编码、关联的明细实例id、凭证号、凭证状态、凭证生成时间、业务对象实例、行程明细ID、行程日期、住宿地、出发地(行程)、目的地(行程)、审批状态、核销金额（本位币）、报销金额（本位币）、报销日期、费用类型、费用金额（本位币）、分摊比例、分摊金额（本位币）、明细id、分摊id、借款余额（本位币）
    blackList = loanBlackType
    if (entity === 'basedata.city') {
      return false
    }
  }
  if (api.getState()['@common'].powers?.customerConditional) {
    if (type === 'list' && (dataType?.elemType?.entity === 'organization.Staff' || name === 'apportions')) {
      return true
    }
  }
  if(name === 'alterFlag'){
    return true
  }
  if(name === 'validApprover' && api.getState()['@common'].powers?.customerConditional){
    return true
  }
  if ('permission' === from && type === 'date') {
    return true
  }
   // 放开审批状态字段
  if ('permission' === from && type == 'text' && name === 'state') return true;

  return (
    !!writeTypes.find(item =>
      item === 'ref' ? types.find(refitem => entity && entity.startsWith(refitem)) : type === item
    ) &&
    blackList.indexOf(name) < 0 &&
    ('permission' === from ? blackAbility.indexOf(ability) < 0 : true)
  )
}

export const getDateFieldsChildren = () => [
  { label: i18n.get('近一个月'), value: DateRangeType.MONTH },
  { label: i18n.get('近一个季度'), value: DateRangeType.SEASON },
  { label: i18n.get('近半年'), value: DateRangeType.HALFYEAR },
  { label: i18n.get('近一年'), value: DateRangeType.YEAR }
]

const dateFields = () => {
  const children = getDateFieldsChildren()
  return [
    { label: i18n.get('早于'), value: '<' },
    { label: i18n.get('不早于'), value: '>=' },
    { label: i18n.get('晚于'), value: '>' },
    { label: i18n.get('不晚于'), value: '<=' },
    { label: i18n.get('属于'), value: 'in', children: [...children, { label: i18n.get('指定日期范围'), value: 'in' }] },
    { label: i18n.get('不属于'), value: 'not in', children: [...children, { label: i18n.get('指定日期范围'), value: 'not in' }] }
  ]
}

export const DateRangeType = {
  MONTH: 'MONTH',
  SEASON: 'SEASON',
  HALFYEAR: 'HALFYEAR',
  YEAR: 'YEAR',
}

export function getDateRangeValues() {
  return {
    [DateRangeType.MONTH]: [
      moment().subtract(1, 'months').startOf('day'),
      moment().endOf('day'),
      DateRangeType.MONTH,
    ],
    [DateRangeType.SEASON]: [
      moment().subtract(3, 'months').startOf('day'),
      moment().endOf('day'),
      DateRangeType.SEASON,
    ],
    [DateRangeType.HALFYEAR]: [
      moment().subtract(0.5, 'years').startOf('day'),
      moment().endOf('day'),
      DateRangeType.HALFYEAR
    ],
    [DateRangeType.YEAR]: [
      moment().subtract(1, 'years').startOf('day'),
      moment().endOf('day'),
      DateRangeType.YEAR,
    ],
  }
}