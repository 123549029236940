/**
 * Created by XuTian on 16/11/2016.
 */

// @i18n-ignore-all
import { getPrivilegesMineList, getRoleConfig } from './permission-action'
import { app as api } from '@ekuaibao/whispered'
import loadable from '@loadable/component'

export default [
  {
    id: '@permission',
    reducer: () => import('./permission-reducer'),
    path: '/permission',
    ref: '/',
    onload: () => import('./permission-view'),
    getMinePrivilegePermissions: () => {
      return api.dispatch(getPrivilegesMineList())
    },
    getRoleConfig: () => {
      return api.dispatch(getRoleConfig())
    }
  },
  {
    point: '@@layers',
    prefix: '@permission',
    onload: () => import('./layers')
  },
  {
    point: '@@menus',
    onload: () => [
      {
        id: 'permission',
        pId: 'enterprise-manage',
        permissions: ['SYS_ADMIN'],
        weight: 3,
        label: '权限管理',
        href: '/permission',
        icon: 'permission-manage'
      }
    ]
  },
  {
    resource: '@permission',
    value: {
      ['elements/utils']: require('./elements/utils'),
      ['elements/customConditional']: loadable(() => import('./elements/customConditional')),
      ['utils/Utils.js']: require('./utils/Utils')
    }
  }
]
