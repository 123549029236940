/**
 * Created by XuTian on 16/11/2016.
 */

export const ID = '@permission'

export default {
  ID: ID,
  GET_TOTAL_PERMISSION: `${ID}/GET_TOTAL_PERMISSION`,
  UPDATE_PERMISSION: `${ID}/UPDATE_PERMISSION`,
  GET_ROLE_CONFIG: `${ID}/GET_ROLE_CONFIG`,
  ADD_PRIVILEGES: `${ID}/ADD_PRIVILEGES`,
  UPDATE_PRIVILEGES: `${ID}/UPDATE_PRIVILEGES`,
  DELETE_PRIVILEGES: `${ID}/DELETE_PRIVILEGES`,
  GET_PRIVILEGES: `${ID}/GET_PRIVILEGES`,
  GET_PRIVILEGES_MINE: `${ID}/GET_PRIVILEGES_MINE`,
  GET_MENUS: `${ID}/GET_MENUS`,
  GET_MENUPERMISSION_LIST: `${ID}/GET_MENUPERMISSION_LIST`,
  GET_MENUPERMISSION: `${ID}/GET_MENUPERMISSION`,
  UPDATE_MENUPERMISSION: `${ID}/UPDATE_MENUPERMISSION`,
  DELETE_MENUPERMISSION: `${ID}/DELETE_MENUPERMISSION`,
}
