import key from './key'
import { Resource } from '@ekuaibao/fetch'
const permission = new Resource('/api/v1/organization/permissions')
const menuPermission = new Resource('/api/v2/organization/menuPermission')
const role = new Resource('/api/v1/flow/planConfigs/roles')
const privileges = new Resource('/api/flow/v2/privileges')

export function updatePermission(data, done) {
  return {
    type: key.UPDATE_PERMISSION,
    payload: permission.PUT('/$name', data),
    done
  }
}

export function getRoleConfig() {
  return {
    type: key.GET_ROLE_CONFIG,
    payload: role.GET()
  }
}

export function addPrivileges(data) {
  return {
    type: key.ADD_PRIVILEGES,
    payload: privileges.POST(data)
  }
}

export function updatePrivileges(data) {
  return {
    type: key.UPDATE_PRIVILEGES,
    payload: privileges.PUT('/$id', data)
  }
}

export function deletePrivileges(id) {
  return {
    type: key.DELETE_PRIVILEGES,
    payload: privileges.DELETE('/$id', { id })
  }
}

export function getPrivilegesList(args) {
  let params = {
    join: `staffSelection.staffIds,staffIds,/v1/organization/staffs?select=id,name`,
    join$1: `staffSelection.departments,departments,/v1/organization/departments?select=id,name`,
    join$2: `staffSelection.roles,roles,/v1/organization/roles?select=id,name`
  }
  if (args) {
    params = { ...params, ...args }
  }
  return {
    type: key.GET_PRIVILEGES,
    payload: privileges.GET('', params)
  }
}

export function getPrivilegesMineList() {
  let params = {
    join: `staffSelection.staffIds,staffIds,/v1/organization/staffs`,
    join$1: `staffSelection.departments,departments,/v1/organization/departments`,
    join$2: `staffSelection.roles,roles,/v1/organization/roles`
  }

  return {
    type: key.GET_PRIVILEGES_MINE,
    payload: privileges.GET('/mine', params)
  }
}

export function getMenus () {
  return {
    type: key.GET_MENUS,
    payload: menuPermission.GET('/menus')
  }
}

export function getMenuPermissionList (params) {
  return {
    type: key.GET_MENUPERMISSION_LIST,
    payload: menuPermission.GET('/list', params)
  }
}

export function getMenuPermission (id) {
  return {
    type: key.GET_MENUPERMISSION,
    payload: menuPermission.GET('/$id', { id })
  }
}

export function updateMenuPermission (params) {
  return {
    type: key.UPDATE_MENUPERMISSION,
    payload: menuPermission.POST('', params)
  }
}

export function deleteMenuPermission (id) {
  return {
    type: key.DELETE_MENUPERMISSION,
    payload: menuPermission.DELETE('/$id', { id })
  }
}
